@import "~leaflet/dist/leaflet.css";

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fff;
  color: #333;
  overflow-x: hidden;
  overflow-y: auto;
}

@media screen {
  .only-print { overflow: hidden; height: 0; }
}